import store from '@/store'

const routes = [
  {
    path: '/',
    component: () => import('./layouts/empty.vue'),
    meta: { middleware: ['auth'] }
  },
  {
    path: '/',
    component: () => import('./layouts/auth.vue'),
    meta: { middleware: ['guest'] },
    children: [
      { path: 'login', name: 'login', component: () => import('./pages/auth/login.vue') },
      { path: 'invitation', name: 'invitation', component: () => import('./pages/auth/invitation.vue') },
      { path: 'forgot-password', name: 'forgot-password', component: () => import('./pages/auth/forgot-password.vue') },
      { path: 'choose-password', name: 'choose-password', component: () => import('./pages/auth/choose-password.vue') },
      { path: 'invalid-link', name: 'invalid-link', component: () => import('./pages/auth/invalid-link.vue') },
      { path: 'password-reset-success', name: 'password-reset-success', component: () => import('./pages/auth/password-reset-success.vue') }
    ]
  },
  // public pages
  // Check assembly instructions and control report pages for how session ids are handled. To allow public to view use the sid and initialize with sid
  {
    path: '/',
    component: () => import('./layouts/clean.vue'),
    children: [
      { path: 'n/:nId', name: 'notification', meta: { middleware: ['notification'] } },
      { path: 'pp/:printPackageId', name: 'pp-templates', component: () => import('./pages/pp-templates.vue') },
      { path: 'assembly-instructions/:id', name: 'assembly-instructions', component: () => import('./pages/assembly-instructions.vue') },
      { path: 'fieldwork/controls/:controlId/reports/:reportId/pdf', name: 'control-report-pdf', meta: { title: 'Schadenbericht PDF', icon: 'mdi-note-search' }, component: () => import('./pages/control-report.vue') },
      { path: '*', name: '404', component: () => import('./pages/errors/code-404.vue') }
    ]
  }
]

const internRoutes = {
  path: '/',
  component: () => import('./layouts/default.vue'),
  meta: { middleware: ['auth', 'intern'] },
  children: [
    { path: '', name: 'index', meta: { title: 'Startseite', icon: 'mdi-home' }, component: () => import('./pages/index.vue') },
    {
      path: 'cubes',
      name: 'cubes',
      redirect: { name: 'cubes-list' },
      meta: { title: 'CityCubes', icon: 'mdi-cube' },
      component: () => import('./pages/cubes/index.vue'),
      children: [
        {
          path: 'list',
          name: 'cubes-list',
          meta: { title: 'Liste', icon: 'mdi-cube-scan' },
          component: () => import('./pages/cubes/list.vue')
        },
        {
          path: 'map',
          name: 'cubes-map',
          meta: { title: 'Karte', icon: 'mdi-map' },
          component: () => import('./pages/cubes/map.vue')
        }
      ]
    },
    { path: 'new-map', name: 'new-map', meta: { title: 'Neue Kartenansicht (WIP)' }, component: () => import('./pages/new-map.vue') },
    { path: 'leads', name: 'leads', meta: { title: 'Leads', icon: 'mdi-account-filter-outline', permissions: ['sales'] }, component: () => import('./pages/leads/index.vue') },
    { path: 'offers', name: 'offers', meta: { title: 'Angebote', icon: 'mdi-offer' }, component: () => import('./pages/offers/index.vue') },
    { path: 'offers/:id', name: 'offer', meta: { title: 'Angebot', icon: 'mdi-offer' }, component: () => import('./pages/offers/_id.vue') },
    {
      path: 'orders',
      name: 'orders',
      redirect: { name: 'contracts' },
      meta: { title: 'Aufträge', icon: 'mdi-clipboard-check-multiple-outline' },
      component: () => import('./layouts/empty.vue'),
      children: [
        { path: 'contracts', name: 'contracts', meta: { title: 'Verträge', icon: 'mdi-file-sign' }, component: () => import('./pages/contracts/index.vue') },
        { path: 'contracts/:id', name: 'contract', meta: { title: 'Vertrag', icon: 'mdi-file-sign' }, component: () => import('./pages/contracts/_id.vue') },
        { path: 'bookings', name: 'bookings', meta: { title: 'Buchungen', icon: 'mdi-book-clock-outline' }, component: () => import('./pages/bookings/index.vue') },
        { path: 'bookings/:id', name: 'booking', meta: { title: 'Buchung', icon: 'mdi-book-clock-outline' }, component: () => import('./pages/bookings/_id.vue') },
        { path: 'booking-requests', name: 'booking-requests', meta: { permissions: ['manage-bookings'], title: 'Buchungsanfragen', icon: 'mdi-book-clock-outline' }, component: () => import('./pages/booking-requests/index.vue') },
        { path: 'special-formats', name: 'special-formats', meta: { title: 'Sonderformate', icon: 'mdi-cube-send' }, component: () => import('./pages/special-formats/index.vue') },
        { path: 'special-formats/:id', name: 'special-format', meta: { title: 'Sonderformat', icon: 'mdi-cube-send' }, component: () => import('./pages/special-formats/_id.vue') },
        { path: 'frame-mounts', name: 'frame-mounts', meta: { title: 'Moskitorahmen', icon: 'mdi-image-filter-frames' }, component: () => import('./pages/frame-mounts/index.vue') },
        { path: 'frame-mounts/requests', name: 'frame-mount-requests', meta: { permissions: ['manage-frames'], title: 'Anfragen', icon: 'mdi-help-box-multiple-outline' }, component: () => import('./pages/frame-mount-requests/index.vue') },
        { path: 'frame-mounts/takedowns', name: 'frame-mount-takedowns', meta: { permissions: ['manage-frames'], title: 'Demontage', icon: 'mdi-close-box-multiple-outline' }, component: () => import('./pages/frame-mount-requests/takedowns.vue') },
        { path: 'frame-mounts/:id', name: 'frame-mount', meta: { title: 'Moskitorahmen', icon: 'mdi-image-filter-frames' }, component: () => import('./pages/frame-mounts/_id.vue') }
      ]
    },
    { path: 'invoices', name: 'invoices', meta: { title: 'Rechnungen', icon: 'mdi-checkbook' }, component: () => import('./pages/invoices/index.vue') },
    { path: 'invoices/:invoiceId', name: 'invoice', meta: { title: 'Rechnung', icon: 'mdi-checkbook' }, component: () => import('./pages/invoices/_invoiceId.vue') },
    { path: 'credit-notes', name: 'credit-notes', meta: { title: 'Gutschriften', icon: 'mdi-wallet-giftcard' }, component: () => import('./pages/credit-notes/index.vue') },
    { path: 'credit-notes/:creditNoteId', name: 'credit-note', meta: { title: 'Gutschrift', icon: 'mdi-wallet-giftcard' }, component: () => import('./pages/credit-notes/_creditNoteId.vue') },
    { path: 'companies', name: 'companies', meta: { title: 'Unternehmen', icon: 'mdi-domain' }, component: () => import('./pages/companies/index.vue') },
    { path: 'companies/:companyId', name: 'company', meta: { title: 'Unternehmen', icon: 'mdi-domain' }, component: () => import('./pages/companies/_companyId.vue') },

    {
      path: 'fieldwork',
      name: 'fieldwork',
      // redirect: { name: 'fieldwork-index' },
      meta: { title: 'Dienstleistungen', icon: 'mdi-map-marker-path', permissions: ['manage-fieldwork'] },
      component: () => import('./layouts/empty.vue'),
      children: [
        { path: 'index', name: 'fieldwork-index', meta: { title: 'Übersicht', permissions: ['manage-fieldwork'], icon: 'mdi-map-marker-path' }, component: () => import('./pages/fieldwork/index.vue') },
        { path: 'map', name: 'fieldwork-map', meta: { title: 'Scouts Karte', permissions: ['manage-fieldwork'], icon: 'mdi-transit-transfer' }, component: () => import('./pages/fieldwork/map.vue') },
        { path: 'task-lists', name: 'task-lists', meta: { title: 'Abfahrtsliste', permissions: ['manage-fieldwork'], icon: 'mdi-format-list-checks' }, component: () => import('./pages/fieldwork/task-lists/index.vue') },
        {
          path: 'task-lists/:listId',
          name: 'task-list',
          meta: { title: 'Abfahrtsliste', icon: 'mdi-map-marker-path' },
          component: () => import('./pages/fieldwork/task-lists/_id.vue'),
          children: [
            { path: ':task/:cubeId', name: 'task-form', meta: { isDialog: true }, component: () => import('./pages/fieldwork/task-lists/_task_cubeId.vue') }
          ]
        },
        { path: 'briefings', name: 'briefings', meta: { title: 'Briefings', permissions: ['manage-fieldwork'], icon: 'mdi-binoculars' }, component: () => import('./pages/fieldwork/briefings/index.vue') },
        { path: 'briefings/:briefingId', name: 'briefing', meta: { title: 'Briefing', permissions: ['manage-fieldwork'], icon: 'mdi-binoculars' }, component: () => import('./pages/fieldwork/briefings/_briefingId.vue') },
        { path: 'controls', name: 'controls', meta: { title: 'Kontrolle', permissions: ['manage-fieldwork'], icon: 'mdi-note-search' }, component: () => import('./pages/fieldwork/controls/index.vue') },
        { path: 'controls/:controlId', name: 'control', meta: { title: 'Kontrolle', permissions: ['manage-fieldwork'], icon: 'mdi-note-search' }, component: () => import('./pages/fieldwork/controls/_controlId.vue') },
        { path: 'controls/:controlId/reports/:reportId', name: 'control-report', meta: { title: 'Schadenbericht', permissions: ['manage-fieldwork'], icon: 'mdi-note-search' }, component: () => import('./pages/fieldwork/controls/reports/_reportId.vue') },
        { path: 'assemblies', name: 'assemblies', meta: { title: 'Montage', permissions: ['manage-fieldwork'], icon: 'mdi-sticker-plus' }, component: () => import('./pages/fieldwork/assemblies/index.vue') },
        { path: 'disassemblies', name: 'disassemblies', meta: { title: 'Demontage', permissions: ['manage-fieldwork'], icon: 'mdi-sticker-remove' }, component: () => import('./pages/fieldwork/disassemblies/index.vue') },
        { path: 'custom-services', name: 'custom-services', meta: { title: 'Sonderdiensleistungen', permissions: ['manage-fieldwork'], icon: 'mdi-pipe-wrench' }, component: () => import('./pages/fieldwork/custom-services/index.vue') },
        { path: 'custom-services/:customServiceId', name: 'custom-service', meta: { title: 'Sonderdiensleistung', permissions: ['manage-fieldwork'], icon: 'mdi-pipe-wrench' }, component: () => import('./pages/fieldwork/custom-services/_customServiceId.vue') }
      ]
    },
    {
      path: 'products',
      name: 'products',
      redirect: { name: 'housing-types' },
      meta: { title: 'Produkte', icon: 'mdi-cube' },
      component: () => import('./layouts/empty.vue'),
      children: [
        {
          path: 'mediae',
          name: 'mediae',
          meta: { title: 'Medien', icon: 'mdi-package' },
          component: () => import('./pages/products/mediae.vue'),
          children: [
            { path: ':id', name: 'media', meta: { isDialog: true }, component: () => import('./pages/products/mediae/_id.vue') }
          ]
        },
        {
          path: 'housing-types',
          name: 'housing-types',
          meta: { title: 'Gehäusetypen', icon: 'mdi-locker-multiple' },
          component: () => import('./pages/products/housing-types.vue'),
          children: [
            { path: ':id', name: 'housing-type', meta: { isDialog: true }, component: () => import('./pages/products/housing-types/_id.vue') }
          ]
        },
        {
          path: 'print-packages',
          name: 'print-packages',
          meta: { title: 'Belegungspakete', icon: 'mdi-layers-outline' },
          component: () => import('./pages/products/print-packages.vue'),
          children: [
            { path: ':id', name: 'print-package', meta: { isDialog: true }, component: () => import('./pages/products/print-packages/_id.vue') }
          ]
        }
      ]
    },
    {
      path: 'admin',
      component: () => import('./layouts/empty.vue'),
      meta: { middleware: ['admin'] },
      children: [
        // admin true is just for hiding from the navigation menu
        { path: '', name: 'admin', redirect: { name: 'users' }, meta: { admin: true, title: 'Admin', icon: 'mdi-account-key' } },
        { path: 'users', name: 'users', meta: { title: 'Benutzer', icon: 'mdi-account-group' }, component: () => import('./pages/admin/users/index.vue') },
        { path: 'users/:userId', name: 'user', meta: { title: 'Benutzer', icon: 'mdi-account' }, component: () => import('./pages/admin/users/_userId.vue') },
        {
          path: 'sales-person-reports',
          name: 'sales-person-reports',
          meta: { title: 'Vertrieblerberichte', icon: 'mdi-file-export-outline' },
          component: () => import('./pages/admin/sales-person-reports/index.vue')
        },
        {
          path: 'quarterly-reports',
          name: 'quarterly-reports',
          meta: { title: 'Quartalsberichte', icon: 'mdi-file-export-outline' },
          component: () => import('./pages/admin/quarterly-reports/index.vue'),
          children: [
            { path: ':quarter', name: 'quarterly-report', meta: { title: 'Quartalsbericht', icon: 'mdi-file-export-outline' }, component: () => import('./pages/admin/quarterly-reports/_quarter.vue') }
          ]
        },
        { path: 'stats', name: 'stats', meta: { title: 'Stats', icon: 'mdi-chart-bell-curve-cumulative' }, component: () => import('./pages/admin/stats.vue') },
        { path: 'all-cubes-map', name: 'all-cubes-map', meta: { title: 'Gesamte CityCubes-Karte', icon: 'mdi-map-legend' }, component: () => import('./pages/admin/all-cubes-map.vue') },
        {
          path: 'gradual-price-maps',
          name: 'gradual-price-maps',
          meta: { title: 'Staffelkonditionen' },
          component: () => import('./pages/admin/gradual-price-maps/index.vue'),
          children: [
            { path: ':gradualPriceMapId', name: 'gradual-price-map', meta: { isDialog: true }, component: () => import('./pages/admin/gradual-price-maps/_gradualPriceMapId.vue') }
          ]
        },
        { path: 'jobs', name: 'jobs', meta: { title: 'Regelmäßige Jobs', icon: 'mdi-hammer' }, component: () => import('./pages/admin/jobs.vue') }
      ]
    },
    {
      path: 'plzs',
      name: 'plzs',
      meta: { title: 'PLZs', icon: 'mdi-map-marker-radius' },
      component: () => import('./pages/admin/plzs/index.vue'),
      children: [
        { path: ':id', name: 'plz', meta: { isDialog: true }, component: () => import('./pages/admin/plzs/_id.vue') }
      ]
    },
    { path: 'audits', name: 'audits', meta: { title: 'Aktivitäten', icon: 'mdi-account-clock' }, component: () => import('./pages/audits.vue') },
    { path: 'system-status', name: 'system-status', meta: { title: 'Systemstatus' }, component: () => import('./pages/system-status.vue') }
  ]
}

const partnerRoutes = {
  path: '/',
  component: () => import('./layouts/default.vue'),
  meta: { middleware: ['auth', 'partner'] },
  children: [
    { path: '', name: 'index', meta: { title: 'Übersicht', icon: 'mdi-home' }, component: () => import('./pages/partner/index.vue') },
    {
      path: 'cubes',
      name: 'cubes',
      redirect: { name: 'cubes-list' },
      meta: { title: 'CityCubes', icon: 'mdi-cube' },
      component: () => import('./pages/cubes/index.vue'),
      children: [
        {
          path: 'list',
          name: 'cubes-list',
          meta: { title: 'Liste', icon: 'mdi-cube-scan' },
          component: () => import('./pages/cubes/list.vue')
        },
        {
          path: 'map',
          name: 'cubes-map',
          meta: { title: 'Karte', icon: 'mdi-map' },
          component: () => import('./pages/cubes/map.vue')
        }
      ]
    },
    { path: 'frame-mount-requests', name: 'frame-mount-requests', meta: { permissions: ['manage-frame-locations:manage-frames'], title: 'Anfragen', icon: 'mdi-help-box-multiple-outline' }, component: () => import('./pages/frame-mount-requests/index.vue') },
    { path: 'frame-mount-takedowns', name: 'frame-mount-takedowns', meta: { permissions: ['manage-frame-locations:manage-frames'], title: 'Demontage', icon: 'mdi-close-box-multiple-outline' }, component: () => import('./pages/frame-mount-requests/takedowns.vue') },
    { path: 'booking-requests', name: 'booking-requests', meta: { permissions: ['manage-bookings'], title: 'Meine Buchungsanfragen', icon: 'mdi-book-clock-outline' }, component: () => import('./pages/booking-requests/index.vue') },
    { path: 'bookings', name: 'partner-bookings', meta: { permissions: ['manage-bookings'], title: 'Meine Buchungen', icon: 'mdi-book-clock-outline' }, component: () => import('./pages/bookings/index.vue') },
    { path: 'fieldwork', name: 'partner-fieldwork', meta: { permissions: ['manage-scouts'], title: 'Dienstleistungen', icon: 'mdi-map-marker-path' }, component: () => import('./pages/fieldwork/task-lists/index.vue') },
    {
      path: 'fieldwork/task-lists/:listId',
      name: 'task-list',
      meta: { title: 'Abfahrtsliste', icon: 'mdi-map-marker-path' },
      component: () => import('./pages/fieldwork/task-lists/_id.vue'),
      children: [
        { path: ':task/:cubeId', name: 'task-form', meta: { isDialog: true }, component: () => import('./pages/fieldwork/task-lists/_task_cubeId.vue') }
      ]
    },
    { path: 'audits', name: 'audits', meta: { title: 'Aktivitäten', icon: 'mdi-account-clock' }, component: () => import('./pages/audits.vue') },
    { path: 'scouts', name: 'scouts', meta: { permissions: ['manage-scouts'], title: 'Scouts', icon: 'mdi-account-group' }, component: () => import('./pages/admin/users/index.vue') },
    { path: 'scouts/:userId', name: 'scout', meta: { permissions: ['manage-scouts'], title: 'Scout', icon: 'mdi-account-group' }, component: () => import('./pages/admin/users/_userId.vue') },
    { path: 'frame-mounts/:id', name: 'frame-mount', meta: { title: 'Moskitorahmen', icon: 'mdi-image-filter-frames', permissions: ['manage-frame-locations:manage-frames'] }, component: () => import('./pages/frame-mounts/_id.vue') }
  ]
}

const scoutRoutes = {
  path: '/',
  component: () => import('./layouts/scout.vue'),
  meta: { middleware: ['auth', 'scout'] },
  children: [
    { path: '', name: 'index', meta: { title: 'Aufgaben' }, component: () => import('./pages/scout/index.vue') },
    {
      path: 'location/:placeKey',
      name: 'location',
      component: () => import('./pages/scout/location.vue'),
      children: [
        { path: ':task/:listId/:cubeId', name: 'task', meta: { isDialog: true }, component: () => import('./pages/scout/task.vue') }
      ]
    }
  ]
}

let routesRegistered = false
export const getUserRoutes = (user) => {
  if (routesRegistered) { return }
  routesRegistered = true
  const { accType, permissions } = user
  if (window.localStorage.getItem('app') === 'scout') {
    if (accType === 'scout' || permissions?.includes('scout')) {
      return scoutRoutes
    }
  }
  if (accType === 'scout') {
    return scoutRoutes
  }
  if (accType === 'partner') {
    return partnerRoutes
  }
  return internRoutes
}

export default () => {
  if (store.state.user) {
    if (store.state.user.accType === 'scout') {
      window.localStorage.setItem('app', 'scout')
    }
    const addRoutes = getUserRoutes(store.state.user)
    if (addRoutes) {
      routes[0] = addRoutes
    }
  } else {
    window.localStorage.removeItem('app')
  }
  return routes
}
